import React from "react"

import FullScreenMenu from "../components/FullScreenMenu/FullScreenMenu"
import Topbar from "../components/Topbar/Topbar"
import Footer from "../components/Footer/Footer"
import pc from "./404-error-image-pc.png"
import logo from "./404-error-logo.png"

import { useHomePage } from "../hooks/use-homePage"

const Layout = () => {
  const homeData = useHomePage()
  const leftTitleTextFirstData = {
    title: homeData.field_lefttitletext_first_title_,
    text: homeData.field_lefttitletext_first_text_,
  }
  const leftTitleTextSecondData = {
    title: homeData.field_lefttitletext_second_title,
    text: homeData.field_lefttitletext_second_text_,
  }
  const leftTitleTextThirdData = {
    title: homeData.field_lefttitletext_third_title_,
    text: homeData.field_lefttitletext_third_text_,
  }
  const twoColumnsData = {
    titleOne: homeData.field_two_columns_col_1_title_,
    textOne: homeData.field_two_columns_col_1_text_,
    titleTwo: homeData.field_two_columns_col_2_title_,
    textTwo: homeData.field_two_columns_col_2_text_,
  }
  const textImageRight = {
    title: homeData.field_text_image_right_title_,
    text: homeData.field_text_image_right_text_,
    relationships: homeData.relationships,
  }
  const textImageLeft = {
    title: homeData.field_text_image_left_title_,
    text: homeData.field_text_image_left_text_,
    relationships: homeData.relationships,
  }
  const fullWidthImgData = {
    img: homeData.relationships.field_full_width_img,
  }
  return (
    <div className="theContainer">
      <FullScreenMenu />
      <div id="container" className="container">
        <Topbar />
        <section class="content404">
          <div class="content404__title">
            <h1>404</h1>
          </div>
          <div class="content404__text">
            <p>oops! page not found</p>
          </div>
          <div class="content404__img">
            <img src={pc} alt="Image pc" />
          </div>
          <div class="content404__logo">
            <img src={logo} alt="Logo" />
          </div>
        </section>
        <Footer />
      </div>
    </div>
  )
}

export default Layout
